/* Desktop */
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');

@font-face {
  font-family: copperplate-gothic-32-bc;
  src: url('./fonts/Copperplate\ Gothic\ 32\ BC.otf');
}

* {
  font-family: copperplate-gothic-32-bc, "Cardo", serif;
}

@media only screen and (min-width: 1000px) {
  .content {
    min-height: calc(100vh - 350px);
  }
}

/* Mobile */
@media only screen and (max-width: 1000px) {
  .content {
    padding: 10px;
    min-height: calc(100vh - 100px);
  }
}

.button:hover {
  background-color: #FABE2C !important;
  color: black;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: "Cardo", serif;

  a {
    font-family: "Cardo", serif;
  }
}

a {
  color:#333;

  &:hover {
    color: #FABE2C;
  }
}

.white {
  color: white;
}

.yellow {
  color: #FABE2C
}

