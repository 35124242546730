/* Desktop */
@media only screen and (min-width: 1000px) {
  #nav {
    text-align: center;
    width: 100%;
    position: relative;

    nav:before {
      content: '';
      position: absolute;
      top: 42%;
      left: 0;
      height: 37px;
      background: #333333;
      width: 100%;
      z-index: -10;
    }

    .navItem {
      font-size: 14pt;
      font-family: "Copperplate Gothic 32 BC";
      line-height: 1.5rem;
      font-weight: 600;
    }

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 1250px;
      margin: auto;

      .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        text-decoration: none;
        color: white;
        transition: all 0.3s ease-in-out;
        font-variant: small-caps;
        padding-bottom: 15px;

        &:hover {
          color: #ffbb00;
        }
      }

      .link::first-letter {
        initial-letter: 2;
      }

      img {
        height: 15rem;
        background-color: white;
      }

      
    }
    #mobileNavButton, .buttonContainer, .buttonContainer .change, #logo {
      display: none;
    }

    .selected {
      color: #ffbb00;
    }
  }
}


/* Mobile */
@media only screen and (max-width: 1000px) {
  #nav {
    width: 100%;
    min-height: 60px;
    border-bottom: 2px solid black;
    text-align: right;
    padding-right: 10px;
    padding-top: 10px;
    // padding-bottom: 10px;

    #logo {
      text-align: center;
      margin-bottom: -55px;
      height: 57px;
      
      a {
        color: black;
        text-decoration: none;
      }

      h1 {
        margin-top: 0;
      }
    }

    .buttonContainer {
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
    }
    
    .bar1, .bar2, .bar3 {
      width: 35px;
      height: 5px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }
    
    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    
    .change .bar2 {opacity: 0;}
    
    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }

    nav {
      transition: all 500ms ease-in-out;
      // margin-top: 0;
      opacity: 1;
      padding-right: 10px;
      img {
        display: none;
      }
      a {
        text-decoration: none;
        color: black;
        padding-bottom: 10px;
        padding-right: 6px;
        font-size: 1.5em;

        p {
          margin: 0;
        }
      }

      .navItem {
        font-size: 14pt;
        font-family: "Copperplate Gothic 32 BC";
        line-height: 1.5rem;
        font-weight: 600;
        font-variant: small-caps;
      }
      
    }
  }

  #nav.closed{
    nav {
      overflow: hidden;
      margin-top: -500px;
      opacity: 0;
    }
  }
}