@media screen and (min-width: 1000px){
  #homeButton {
    margin-top: 30px;
    margin-left: 30px;
    padding: 15px 30px;
  }
}

@media screen and (max-width: 1000px){
  #homeButton {
    margin-left: 20%;
    margin-bottom: 20px;
    padding: 15px 30px;
  }
}
