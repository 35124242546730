.officialInfo {
  li:not(:last-child) { 
    margin-bottom: 10px;  
  }
}

@media only screen and (min-width: 1000px) {
  #infoButton {
    margin: 30px;
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 1000px) {
  #infoButton {
    margin-left: 20%;
    padding: 15px 30px;
  }
}
