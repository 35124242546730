.footer {
    p {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 10px;
    color: #333333;
    font-family: copperplate-gothic-32-bc;

    a {
      color: #333333;
      text-decoration: underline;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #ffbb00;
      }
    }
  }
}

@media screen and (min-width: 1000px){
  .footer {  
    .copy {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      gap: 10px;

      p {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 1000px){
  .copy {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 10px;
    
    p {
      font-size: 0.6rem;
    }
  }
}
